import React from 'react'
import Lottie from 'lottie-react'
import { Grid} from '@mui/material'
import storyAnim from '../../lottie/team.json'
import './landing_section3.css'

export default function Landing_section3() {
    return (
        <div className='container-fluid about'>
            <div className='container-fixed' id="about-us-content">
                <div className='section_heading'>
                    <div className='section_heading_title'>We are IQServe</div>
                    <div className='section_heading_body'>Bringing exceptional value to our clients through a combination of our experience, professionalism, commitment and ingenuity</div>
                </div>
                <Grid className="story_section" container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={12} sm={12} md={7}>
                        <div className="storyAnim">
                            <Lottie animationData={storyAnim} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <div className="story_text_group">
                            <div className='story_title'><h5>Our Story</h5></div>
                            <p>iQserve Enterprises Limited started out of a desire to assist small and medium businesses in the following areas:</p>
                            <ul>
                                <li>Understanding whether their business is profitable</li>
                                <li>Ensuring their business model is fit for purpose</li>
                                <li>De-mystifying financial information to owners</li>
                                <li>Record keeping</li>
                                <ul>Compliance:
                                <li>Board of Inland Revenue</li>
                                <li>Ministry of Legal Affairs</li>
                                </ul>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
