import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import './contact.css'
import Lottie from 'lottie-react'
import { Grid } from '@mui/material'
import downAnim from '../lottie/scroll-down-arrow.json'
import * as Scroll from 'react-scroll';
import { Backdrop, CircularProgress, Snackbar, Alert } from "@mui/material";
import { sendMail } from '../api'
const blankForm = {
  email: '',
  first_name: '',
  last_name: '',
  message: '',
  phone: '',
  fnameF: false,
  lnameF: false,
  phoneF: false,
  emailF: false,
}

export const Contact = () => {
  const [formData, setFormData] = useState(blankForm)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isFailIncomplete, setIsFailIncomplete] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const scroller = Scroll.scroller;
  const navigateTo = useNavigate();
  const Element = Scroll.Element;
  const scrollToForm = () => {
    scroller.scrollTo("contact_form", {
      duration: 200,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page

    })
  }
  
  const handleSubmit = async() => {
    console.log("MAILING TO michael.edwards@iqserve.org")
    let wegood = 
    formData.email.length>5 && 
    formData.email.includes("@") &&
    formData.first_name.length>0 &&
    formData.last_name.length>0 &&
    formData.message.length>0 &&
    formData.phone.length>0
    if(wegood){
      let reply = await sendMail(formData)
      if(reply.response==="success"){
        setIsSuccess(true)
      }else{
        alert("Something went wrong, please try again later.")
      }
    }else{
      setIsFailIncomplete(true)
    }

  }
  return (
    <div className='container-fluid contact'>
      <div className='container-fluid cbanner'>
        <div className="cbanner_text_grp">
          <div className='cbanner_text_heading_top'>Contact Us</div>
          <div className='cbanner_text_heading'>Let's talk about your business</div>
          <div className='cbanner_text_body'>Drop us a line through the form below. Or start a chat in
            the bottom right corner of the screen.</div>
          <div className="down_arrow" onClick={scrollToForm}>
            <Lottie animationData={downAnim} width={50} height={75} />
          </div>
        </div>

      </div>
      <div className='container-fixed contact_form_container'>
        <Element name='contact_form' className='contact_form'>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={4} md={6}>
                <div className="contact_input_item">
                  <label
                    className={
                      formData.fnameF || formData.first_name.length > 0
                        ? "contact_label focussed"
                        : "contact_label"
                    }
                  >
                    First name
                  </label>
                  <input
                    // placeholder="e.g Jane Doe"
                    className="contact_input"
                    value={formData.first_name}
                    type="text"
                    onFocus={() => setFormData({ ...formData, fnameF: true })}
                    onBlur={() => {
                      if (formData.first_name.length === 0)
                        setFormData({ ...formData, fnameF: false });
                    }}
                    onInput={(e) =>
                      setFormData({
                        ...formData,
                        first_name: e.currentTarget.value,
                      })
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={6}>
                <div className="contact_input_item">
                  <label
                    className={
                      formData.lnameF || formData.last_name.length > 0
                        ? "contact_label focussed"
                        : "contact_label"
                    }
                  >
                    Last name
                  </label>
                  <input
                    // placeholder="e.g Jane Doe"
                    className="contact_input"
                    value={formData.last_name}
                    type="text"
                    onFocus={() => setFormData({ ...formData, lnameF: true })}
                    onBlur={() => {
                      if (formData.last_name.length === 0)
                        setFormData({ ...formData, lnameF: false });
                    }}
                    onInput={(e) =>
                      setFormData({
                        ...formData,
                        last_name: e.currentTarget.value,
                      })
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={6}>
                <div className="contact_input_item">
                  <label
                    className={
                      formData.emailF || formData.email.length > 0
                        ? "contact_label focussed"
                        : "contact_label"
                    }
                  >
                    Email
                  </label>
                  <input
                    // placeholder="e.g Jane Doe"
                    className="contact_input"
                    value={formData.email}
                    type="text"
                    onFocus={() => setFormData({ ...formData, emailF: true })}
                    onBlur={() => {
                      if (formData.first_name.length === 0)
                        setFormData({ ...formData, emailF: false });
                    }}
                    onInput={(e) =>
                      setFormData({
                        ...formData,
                        email: e.currentTarget.value,
                      })
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={6}>
                <div className="contact_input_item">
                  <label
                    className={
                      formData.phoneF || formData.phone.length > 0
                        ? "contact_label focussed"
                        : "contact_label"
                    }
                  >
                    Phone contact
                  </label>
                  <input
                    // placeholder="e.g Jane Doe"
                    className="contact_input"
                    value={formData.phone}
                    type="text"
                    onFocus={() => setFormData({ ...formData, phoneF: true })}
                    onBlur={() => {
                      if (formData.phone.length === 0)
                        setFormData({ ...formData, phoneF: false });
                    }}
                    onInput={(e) =>
                      setFormData({
                        ...formData,
                        phone: e.currentTarget.value,
                      })
                    }
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
              <div className="contact_input_item">
                <label
                  className="contact_label focussed"
                >
                  message
                </label>
                <textarea
                  // placeholder="e.g Jane Doe"
                  className="contact_input"
                  value={formData.message}
                  type="text"

                  onInput={(e) =>
                    setFormData({
                      ...formData,
                      message: e.currentTarget.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
              <div className="registerBtn" onClick={handleSubmit}>
                Send Message
              </div>
            </Grid>
          </Grid>
        </Element>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: 100 }}
        open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={isSuccess}
        autoHideDuration={5000}
        onClose={() => { setIsSuccess(false); navigateTo('/') }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => { setIsSuccess(false); navigateTo('/') }} severity="success" sx={{ width: '100%' }}>
          Your message was sent successfully! We will respond to you as soon as we can.
        </Alert>
      </Snackbar>
      <Snackbar
        open={isFailIncomplete}
        autoHideDuration={5000}
        onClose={() => setIsFailIncomplete(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => { setIsFailIncomplete(false) }} severity="error" sx={{ width: '100%' }}>
          Please fill out All fields.
        </Alert>
      </Snackbar>
      <MessengerCustomerChat
      pageId="105698938541542"
      appId="438423858493754"      
      />
    </div >
  )
}
