import axios from "axios";
//const DEV_URL = "http://localhost:5000/api/";
const DEV_ROOT = "http://localhost:3000"
const LOOPTT = "https://tt.loopnews.com/search-results/search?keys=tax%2C+accounting+&domain=All&sort_by=created&sort_order=DESC"
const PROD_URL = "https://iqserver.cyclic.app/api/"
//// const PROD_PHP_URL = "https://iqserve.org/mongo.php?"

export const login = async ({ email, password }) => {

  let data = await axios
    .post(PROD_URL + "login", { email, password })
    .then((result) => result.data);
  return data;
};
export const register = async (formdata) => {

  let data = await axios
    .post(PROD_URL + "register", formdata)
    .then((result) => result.data);
  return data;
};

export const postQuestion = async (formdata) => {

  try {
    let data = await axios.post(PROD_URL + "create-question", formdata).then(res => res.data)
    return data;
  } catch (error) {
    console.log(error.message)
  }
  return {};
}

export const getQuestions = async () => {
  try {
    let data = await axios.get(PROD_URL + 'questions').then(rs => rs.data)
    return data;
  } catch (error) {
    console.log(error.message)
  }
  return {};

}
export const postAnswerToQuestion = async (formData) => {

  try {
    let data = await axios.post(PROD_URL + "create-answer", formData).then(res => res.data)
    return data;
  } catch (error) {
    console.log("DE ERROR WAS: " + error.message)
  }
  return {};
}
export const loadNewsDay = async () => {
  try {
    let raw = await axios('https://iqserve.org/newsday.json').then(result => result.data)
    return raw.data
  } catch (error) {
    console.log(error.message)
  }
}
export const loadExpress = async () => {
  try {
    let raw = await axios('https://iqserve.org/express.json').then(result => result.data)
    return raw.data
  } catch (error) {
    console.log(error.message)
  }
}
export const sendMail = async (formData) => {
  let params = new URLSearchParams();
  params.append('email', formData.email);
  params.append('first_name', formData.first_name);
  params.append('last_name', formData.last_name);
  params.append('phone', formData.phone);
  params.append('message', formData.message);

  try {
    let response = await axios.post("https://iqserve.org/mail.php", params).then(result => result.data)
    //console.log(response)
    return response;
  } catch (error) {
    console.log(error.message)
  }
}
export const loadLoopTT = async () => {
  let returnArr = [];
  try {
    let raw = await axios('http://localhost:5000/api/scrape').then(result => result.data)
    let doc = new DOMParser().parseFromString(raw, 'text/html');
    let searchSection = doc.querySelector('.views-element-container')
    let result = searchSection.childNodes[0].childNodes[1].childNodes
    //let resultArr = Array.from(result)
    //console.log(result)
    for (let newsItem of result) {
      console.log(newsItem.childNodes[0].querySelector('.brk-news-img').querySelector('a').href)
    }
    // resultArr.forEach(element => {
    //  let imgData = new DOMParser().parseFromString(element.childNodes[0],'text/html')
    //  console.log(imgData)
    //   let hlTitle = new DOMParser().parseFromString(element.childNodes[1].firstChild.innerHTML,'text/html').querySelector('.brk-news-title').querySelector('a').innerHTML
    //   let hlLink = new DOMParser().parseFromString(element.childNodes[1].firstChild.innerHTML,'text/html').querySelector('.brk-news-title').querySelector('a').href
    //   let hlDate = new DOMParser().parseFromString(element.childNodes[1].firstChild.innerHTML,'text/html').querySelector('.auther-dte').getAttribute('data-publish')
    //   //console.log('data url: '+hlLink.replace("http://"+window.location.host,"https://tt.loopnews.com"))

    //   let newsItem = {
    //   img:imgData,
    //   hTitle:hlTitle,
    //   hLink: hlLink,
    //   hDate:hlDate   
    // }

    // returnArr.push(newsItem)
    //console.log(returnArr)
    // });    
    return returnArr;
  } catch (error) {
    console.log(error.message)
  }
  return returnArr
}