import React from 'react'

export default function Acca() {
  return (
    <a href="https://www.accaglobal.com"  target='_blank'  rel="noopener noreferrer" >
                  <svg  className='acca' viewBox="0 0 357 117" version="1.1">
                     <g id="Logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <polygon id="Fill-2" fill="#C80000" points="0 117 117 117 117 0 0 0"></polygon>
                      <g id="Group-31" transform="translate(7.000000, 47.000000)">
                        <path d="M51.2125141,17.5249396 L44.9913728,17.5249396 C44.8577445,17.5249396 44.7389637,17.5928505 44.6706648,17.6952089 C43.9005697,19.0219313 42.1921067,21.1714578 38.9503823,21.1714578 C34.7089201,21.1714578 32.0868352,17.4658867 32.0868352,13.6235097 C32.0868352,10.0232498 34.536688,6.21433612 38.9187075,6.21433612 C41.9218805,6.21433612 43.7659515,8.06367688 44.6924413,9.78014856 C44.7033295,9.8018013 44.7181771,9.82640669 44.7300552,9.85002786 C44.8003338,9.94943361 44.9171349,10.0163603 45.0507632,10.0163603 L51.2827927,10.0163603 C51.4926387,10.0163603 51.6648707,9.84412256 51.6648707,9.63448468 C51.6648707,9.60102136 51.6599215,9.56952646 51.6520028,9.53803157 C51.6470536,9.51933148 51.6411146,9.5016156 51.6351756,9.48488394 C50.4048051,5.57558032 46.2148144,0.521634169 38.9503823,0.521634169 C30.6040557,0.521634169 25.5667624,7.17394615 25.5667624,13.6589415 C25.5667624,20.5179387 31.0178086,26.8641597 39.0206609,26.8641597 C44.7795472,26.8641597 49.7188463,23.3967688 51.5629173,18.0603528 C51.5668766,18.0495265 51.570836,18.0396843 51.5747953,18.0288579 C51.5876633,17.9904735 51.5945921,17.9511049 51.5945921,17.9087837 C51.5945921,17.6981616 51.4203804,17.5249396 51.2125141,17.5249396" id="Fill-3" fill="#FFFFFF"></path>
                        <path d="M78.8963453,17.5249396 L72.675204,17.5249396 C72.5415757,17.5249396 72.4227949,17.5928505 72.354496,17.6952089 C71.5844009,19.0219313 69.8759379,21.1714578 66.6361932,21.1714578 C62.3927513,21.1714578 59.7706664,17.4658867 59.7706664,13.6235097 C59.7706664,10.0232498 62.2205192,6.21433612 66.6025386,6.21433612 C69.6057117,6.21433612 71.4497827,8.06367688 72.3762725,9.78014856 C72.3891404,9.8018013 72.4020083,9.82640669 72.4148762,9.85002786 C72.484165,9.94943361 72.600966,10.0163603 72.7345944,10.0163603 L78.9666239,10.0163603 C79.1764698,10.0163603 79.3487019,9.84412256 79.3487019,9.63448468 C79.3487019,9.60102136 79.3457324,9.56952646 79.335834,9.53803157 C79.3308848,9.51933148 79.3249458,9.5016156 79.3190067,9.48488394 C78.0886362,5.57558032 73.8996355,0.521634169 66.6361932,0.521634169 C58.2878868,0.521634169 53.2505936,7.17394615 53.2505936,13.6589415 C53.2505936,20.5179387 58.7016398,26.8641597 66.7044921,26.8641597 C72.465358,26.8641597 77.4026774,23.3967688 79.2467484,18.0603528 C79.2507078,18.0495265 79.2546672,18.0396843 79.2566468,18.0288579 C79.2695147,17.9904735 79.2784233,17.9511049 79.2784233,17.9087837 C79.2784233,17.6981616 79.1061912,17.5249396 78.8963453,17.5249396" id="Fill-5" fill="#FFFFFF"></path>
                        <path d="M15.973139,17.2997512 L15.9582914,17.2554615 L13.6103921,9.42209099 C13.5826766,9.32071681 13.4916114,9.24690065 13.3817392,9.24690065 C13.2748365,9.24690065 13.185751,9.31481151 13.161005,9.41421727 L10.7972683,17.2662878 L10.7873699,17.2977827 C10.7834105,17.312546 10.7824207,17.3273092 10.7824207,17.3420724 C10.7824207,17.468052 10.8843741,17.5674578 11.0100838,17.5674578 L15.7484454,17.5674578 C15.8761347,17.5674578 15.9770984,17.468052 15.9770984,17.3420724 C15.9770984,17.3273092 15.9751187,17.3145144 15.973139,17.2997512 Z M17.8607629,22.9481634 C17.8033523,22.8103733 17.6657646,22.7119517 17.5044207,22.7119517 L9.18580956,22.7119517 C9.0264454,22.7119517 8.88885771,22.8103733 8.82946734,22.9481634 L7.65650753,26.1488319 L7.65452785,26.1508004 C7.59711716,26.2885905 7.45952947,26.3830752 7.30412466,26.3830752 L1.13643473,26.3830752 C0.923619239,26.3830752 0.750397326,26.2128059 0.750397326,26.0021838 C0.750397326,25.9529731 0.760295721,25.9067149 0.778112832,25.8643937 L0.784051869,25.847662 L10.7111522,1.25211885 L10.7230303,1.22456082 C10.7834105,1.09366017 10.9170388,1.00114392 11.0734335,1.00114392 L15.6969738,1.00114392 C15.8464396,1.00114392 15.9790781,1.08972331 16.0394583,1.21668709 L16.0602449,1.26491365 L25.8745035,25.8348672 L25.8952902,25.8860464 C25.9081581,25.9214782 25.915087,25.9598626 25.915087,26.0021838 C25.915087,26.2128059 25.7428549,26.3830752 25.5330089,26.3830752 L19.3009794,26.3830752 C19.1396356,26.3830752 19.0040276,26.2885905 18.9446372,26.1517846 L18.9406778,26.1429266 L17.8607629,22.9481634 Z" id="Fill-7" fill="#FFFFFF"></path>
                        <path d="M94.4044577,17.2997512 L94.3896101,17.2554615 L92.0417108,9.42209099 C92.0139953,9.32071681 91.92293,9.24690065 91.812068,9.24690065 C91.7061552,9.24690065 91.6170696,9.31481151 91.5923237,9.41421727 L89.2285869,17.2662878 L89.2186885,17.2977827 C89.2147292,17.312546 89.2137393,17.3273092 89.2137393,17.3420724 C89.2137393,17.468052 89.3156928,17.5674578 89.4414024,17.5674578 L94.1817438,17.5674578 C94.3074534,17.5674578 94.408417,17.468052 94.408417,17.3420724 C94.408417,17.3273092 94.4064374,17.3145144 94.4044577,17.2997512 Z M96.2920816,22.9481634 C96.2346709,22.8103733 96.0970832,22.7119517 95.9357394,22.7119517 L87.6171282,22.7119517 C87.4577641,22.7119517 87.3201764,22.8103733 87.260786,22.9481634 L86.0878262,26.1488319 L86.0858465,26.1508004 C86.0264561,26.2885905 85.8908481,26.3830752 85.7334636,26.3830752 L79.5677534,26.3830752 C79.3549379,26.3830752 79.181716,26.2128059 79.181716,26.0021838 C79.181716,25.9529731 79.1916144,25.9067149 79.2094315,25.8643937 L79.2153705,25.847662 L89.1424709,1.25211885 L89.154349,1.22456082 C89.2147292,1.09366017 89.3483575,1.00114392 89.5047521,1.00114392 L94.1282925,1.00114392 C94.2777582,1.00114392 94.4103967,1.08972331 94.4707769,1.21668709 L94.4915635,1.26491365 L104.307802,25.8348672 L104.326609,25.8860464 C104.339477,25.9214782 104.346406,25.9598626 104.346406,26.0021838 C104.346406,26.2128059 104.174174,26.3830752 103.964328,26.3830752 L97.7322981,26.3830752 C97.5709542,26.3830752 97.4333665,26.2885905 97.3759559,26.1517846 L97.3719965,26.1429266 L96.2920816,22.9481634 Z" id="Fill-9" fill="#FFFFFF"></path>
                        <polygon id="Fill-11" fill="#555555" points="143.246504 23.8391736 145.663692 23.8391736 145.796331 5.94909006 140.325488 5.94909006 140.19087 9.35349118 136.835314 9.35349118 136.968942 3.211987 158.285136 3.211987 158.419754 9.35349118 155.061228 9.35349118 154.9276 5.94909006 149.557721 5.94909006 149.423103 23.8391736 152.142192 23.8391736 152.142192 26.5762767 143.246504 26.5762767"></polygon>
                        <path d="M159.921241,23.8391736 L161.90191,23.8391736 L162.036528,3.646026 L159.518377,3.646026 L159.518377,0.908922934 L165.929567,0.908922934 L165.830583,9.21963788 C165.830583,10.3879016 165.629646,11.5896286 165.394064,13.0590622 L165.461373,13.0590622 C166.165149,10.8229248 168.111173,8.82004643 171.604317,8.82004643 C175.699283,8.82004643 177.679952,11.3898329 177.679952,15.5284587 C177.679952,18.2990251 177.578988,21.0705757 177.545334,23.8391736 L179.827904,23.8391736 L179.827904,26.5762767 L171.805254,26.5762767 L171.805254,23.8391736 L173.785923,23.8391736 C173.819578,21.3018663 173.920541,18.7340483 173.920541,16.1967409 C173.920541,13.7598236 172.778267,11.9577252 170.394733,11.9577252 C167.811252,11.9577252 165.862258,13.9930826 165.796929,17.2301671 L165.6633,23.8391736 L168.0112,23.8391736 L168.0112,26.5762767 L159.921241,26.5762767 L159.921241,23.8391736 Z" id="Fill-13" fill="#555555"></path>
                        <path d="M186.227018,0.508150418 C187.672184,0.508150418 188.812479,1.64295079 188.812479,3.07892108 C188.812479,4.51390715 187.672184,5.64870752 186.227018,5.64870752 C184.783832,5.64870752 183.643537,4.51390715 183.643537,3.07892108 C183.643537,1.64295079 184.783832,0.508150418 186.227018,0.508150418 Z M181.997434,23.8389768 L184.280004,23.8389768 L184.347313,11.9575283 L181.697512,11.9575283 L181.697512,9.22042526 L188.108703,9.22042526 L188.041394,23.8389768 L190.389293,23.8389768 L190.389293,26.5770641 L181.997434,26.5770641 L181.997434,23.8389768 Z" id="Fill-15" fill="#555555"></path>
                        <path d="M192.867455,23.8391736 L194.848124,23.8391736 L194.915433,11.9577252 L192.464591,11.9577252 L192.464591,9.21963788 L198.675833,9.21963788 C198.675833,10.3879016 198.57586,11.5896286 198.340278,13.0590622 L198.407587,13.0590622 C199.111363,10.8229248 201.057387,8.82004643 204.550531,8.82004643 C208.645497,8.82004643 210.626166,11.3898329 210.626166,15.5284587 C210.626166,18.2990251 210.525202,21.0705757 210.491548,23.8391736 L212.774117,23.8391736 L212.774117,26.5762767 L204.751468,26.5762767 L204.751468,23.8391736 L206.732137,23.8391736 C206.765792,21.3018663 206.866755,18.7340483 206.866755,16.1967409 C206.866755,13.7598236 205.724481,11.9577252 203.340947,11.9577252 C200.757466,11.9577252 198.808472,13.9930826 198.743143,17.2301671 L198.609514,23.8391736 L200.957414,23.8391736 L200.957414,26.5762767 L192.867455,26.5762767 L192.867455,23.8391736 Z" id="Fill-17" fill="#555555"></path>
                        <polygon id="Fill-19" fill="#555555" points="215.113702 23.8391736 217.227999 23.8391736 217.362617 3.646026 214.308963 3.646026 214.308963 0.908922934 221.124008 0.908922934 220.989389 16.7646332 227.165988 12.0246518 227.165988 11.9577252 224.681491 11.9577252 224.681491 9.21963788 232.872413 9.21963788 232.872413 11.9577252 231.127326 11.9577252 224.883418 17.0963138 231.631154 23.8391736 233.610833 23.8391736 233.610833 26.5762767 225.218974 26.5762767 225.218974 23.8391736 227.266952 23.8391736 220.989389 17.7645961 220.989389 23.8391736 223.104676 23.8391736 223.104676 26.5762767 215.113702 26.5762767"></polygon>
                        <path d="M256.157892,8.48600371 C255.788682,7.45159331 255.654064,6.8502377 255.5531,6.35025627 L255.485791,6.35025627 C255.384827,6.81677437 255.250209,7.45159331 254.847344,8.48600371 L252.263863,15.0950102 L258.474116,15.0950102 L256.157892,8.48600371 Z M243.233558,23.8387799 L245.247881,23.8387799 L253.774358,3.21257753 L257.936634,3.21257753 L265.824665,23.8387799 L267.905307,23.8387799 L267.905307,26.5768672 L259.31251,26.5768672 L259.31251,23.8387799 L261.460462,23.8387799 L259.447129,18.031909 L251.188898,18.031909 L249.007291,23.8387799 L251.424479,23.8387799 L251.424479,26.5768672 L243.233558,26.5768672 L243.233558,23.8387799 Z" id="Fill-21" fill="#555555"></path>
                        <path d="M269.907159,23.8391736 L271.887827,23.8391736 L272.021456,3.646026 L269.504294,3.646026 L269.504294,0.908922934 L275.915484,0.908922934 L275.8165,9.21963788 C275.8165,10.3879016 275.615563,11.5896286 275.379981,13.0590622 L275.44729,13.0590622 C276.151066,10.8229248 278.097091,8.82004643 281.590234,8.82004643 C285.6852,8.82004643 287.665869,11.3898329 287.665869,15.5284587 C287.665869,18.2990251 287.564905,21.0705757 287.531251,23.8391736 L289.813821,23.8391736 L289.813821,26.5762767 L281.791172,26.5762767 L281.791172,23.8391736 L283.77184,23.8391736 C283.805495,21.3018663 283.906459,18.7340483 283.906459,16.1967409 C283.906459,13.7598236 282.764184,11.9577252 280.38065,11.9577252 C277.797169,11.9577252 275.848175,13.9930826 275.782846,17.2301671 L275.649218,23.8391736 L277.997117,23.8391736 L277.997117,26.5762767 L269.907159,26.5762767 L269.907159,23.8391736 Z" id="Fill-23" fill="#555555"></path>
                        <path d="M304.371786,15.9625961 L304.371786,15.6289471 C304.371786,14.193961 303.430449,11.5562637 300.243166,11.5562637 C297.052913,11.5562637 295.979927,13.860312 295.845309,15.9625961 L304.371786,15.9625961 Z M307.223514,25.7752238 C306.01591,26.2761894 303.699685,26.977935 301.08057,26.977935 C295.307826,26.9444717 291.882981,23.4052331 291.882981,17.9654745 C291.882981,12.8249174 294.803998,8.82014485 300.442123,8.82014485 C305.680354,8.82014485 308.332134,12.6251216 308.332134,17.9989378 L308.332134,18.4999034 L295.845309,18.4999034 C295.878963,21.869857 298.361481,24.040052 301.81998,24.040052 C304.002576,24.040052 305.914946,23.4711755 307.425441,22.7054559 L307.223514,25.7752238 Z" id="Fill-25" fill="#555555"></path>
                        <path d="M317.822814,24.0396583 C320.743831,24.0396583 322.321635,21.8369842 322.321635,19.6333259 L322.321635,18.934533 C318.427606,18.9679963 315.036416,19.1323603 315.036416,21.7031309 C315.036416,23.0062321 316.044073,24.0396583 317.822814,24.0396583 Z M312.016416,10.3885905 C312.954784,9.95455153 315.203699,8.81975116 318.931434,8.81975116 C324.100376,8.81975116 325.7445,11.3560743 325.7445,15.4612368 C325.7445,18.2662507 325.643536,21.0368171 325.609882,23.8388784 L327.959761,23.8388784 L327.959761,26.5769656 L322.421609,26.5769656 C322.421609,25.4766128 322.522572,24.1400483 322.724499,22.9727688 L322.65719,22.9727688 C321.91778,24.9421838 320.509239,26.9775413 316.848812,26.9775413 C312.652883,26.9775413 311.277006,24.2069749 311.277006,22.0033166 C311.277006,17.4306518 315.943109,16.8312646 318.796816,16.6974113 L322.387954,16.4966314 L322.387954,15.1954986 C322.387954,13.0587669 321.615879,11.5568542 318.52857,11.5568542 C317.218022,11.5568542 315.943109,11.9239666 315.371972,12.1582098 L315.371972,14.694533 L312.016416,14.694533 L312.016416,10.3885905 Z" id="Fill-27" fill="#555555"></path>
                        <path d="M343.124696,12.4910715 C342.351631,12.0245534 341.175702,11.7568468 339.799825,11.7568468 C336.343305,11.7568468 334.396291,14.7606722 334.396291,18.1660576 C334.396291,21.4021578 335.805822,23.8390752 338.625875,23.8390752 C341.645875,23.8390752 343.124696,21.3686945 343.124696,17.3304587 L343.124696,12.4910715 Z M349.166676,26.5771625 L343.257334,26.5771625 C343.257334,25.3429564 343.424617,24.0398552 343.59289,23.0389081 L343.525581,23.0389081 C342.620867,25.3764197 340.707508,26.9777382 337.719182,26.9777382 C333.523252,26.9777382 330.433963,23.9060019 330.433963,18.7339499 C330.433963,12.9920371 333.858808,8.819948 339.799825,8.819948 C341.041083,8.819948 342.217013,8.98628041 343.257334,9.21953946 L343.324643,3.64592758 L340.13637,3.64592758 L340.13637,0.908824513 L346.951415,0.908824513 L346.816797,23.8390752 L349.166676,23.8390752 L349.166676,26.5771625 Z" id="Fill-29" fill="#555555"></path>
                      </g>
                    </g>
                  </svg>
                </a>
  )
}
