import React from 'react'
import './landing_section4.css'

export default function Landing_section4() {
    return (
        <div className='fancybackground' >
            <div className="container-fluid team">
                <div className='container-fixed'>
                    <div className='section_heading'>
                        <div className='section_heading_title'>Meet our Team</div>
                        <div className='section_heading_body'>Our executive team brings over a half century's worth of combined experience in the fields of accounting and business consulting. They have a proven track record of going that extra mile for their clients.</div>
                    </div>
                    <div className="id_sect" >
                        <div className='id_card mike'>                            
                             <div className='id_blurb_mike'>
                                <div className='id_heading'>
                                    Michael Edwards FCCA, CA, ICATT MIP 952
                                </div>
                                <p className="id_content">Michael has over 24 years of working experience in the
                                    following industries: Banking Medical Auditing Food
                                    Manufacturing Education Oil and Gas His experience includes
                                    auditing, costing, accounting, budgeting, forecasting,
                                    management reporting and business process re-engineering.</p>
                            </div>                             
                            <div className='id_pic_mike'></div>
                        </div>                        
                        <div className='id_card avionne'>                           
                            <div className='id_blurb_avionne'>
                                <div className='id_heading'>
                                    Mrs. Avionne Atkinson ACCA
                                </div>
                                <p className="id_content">Avionne has over 10 years of working following industries: Oil and Gas Outsourced Maintenance & Supervision Her experience includes financial reporting, costing, accounting, budgeting, forecasting and management reporting..</p>
                            </div>                            
                            <div className='id_pic_avionne'></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    )
}
