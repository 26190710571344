import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Snackbar, Alert } from "@mui/material";
import "./register.css";
import EO from "../img/eye_open.svg";
import EC from "../img/eye_closed.svg";
import bcrypt from "bcryptjs";
import { register } from "../api";
import { useDispatch } from "react-redux";
import { loginUser } from "../features/loginSlice";
import { useNavigate } from "react-router-dom";

const userProfileImagesArray = [
  "https://iqserve.org/img/userImg1.png",
  "https://iqserve.org/img/userImg2.png",
  "https://iqserve.org/img/userImg3.png",
  "https://iqserve.org/img/userImg4.png",
  "https://iqserve.org/img/userImg5.png",
  "https://iqserve.org/img/userImg6.png",
  "https://iqserve.org/img/userImg7.png",
  "https://iqserve.org/img/userImg8.png",
  "https://iqserve.org/img/userImg9.png",
  "https://iqserve.org/img/userImg10.png",

];
/*
    email:String,
    password:String,
    username:String,
    phone:String,
    address:String,
    first_name:String,
    last_name:String,
    profile_pic:String,
    accesslevel:String,
    is_mod:Boolean,
*/
const initialFD = {
  email: "",
  password: "",
  username: "",
  phone: "",
  address: "",
  first_name: "",
  last_name: "",
  profile_pic: "",
  accesslevel: "user",
  is_mod: false,
  unameF: false,
  emailF: false,
  passF: false,
  phoneF: false,
  addF: false,
  passType: "password",
};
let eye_open = false;
export default function Register() {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoaded] = useState(true);
  const [formData, setFormData] = useState(initialFD);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isShortPass, setIsShortPass] = useState(false);
  const [isFailIncomplete, setIsFailIncomplete] = useState(false)
  //const [emailFocussed, setEmailFocussed] = useState(false)
  //const [passFocussed, setPassFocussed] = useState(false)
  //const [email, setEmail] = useState('')
  //const [password, setPassword] = useState('')
  const resetForm=()=>{
    setFormData(initialFD)
  }
  const handleEyeClick = () => {
    console.log(userProfileImagesArray[Math.floor(Math.random() * userProfileImagesArray.length)])
    eye_open = !eye_open;
    if (eye_open) {
      //console.log("EYE IS OPEN");
      setFormData({ ...formData, passType: "text" });
    } else {
      //console.log("EYE IS CLOSED");
      setFormData({ ...formData, passType: "password" });
    }
  };
  const showPasswordBtn = {
    position: "absolute",
    zIndex: 10,
    height: "15px",
    width: "15px",
    right: "10%",
    marginTop: "2rem",
    display: formData.password.length > 0 ? "flex" : "none",
    backgroundImage: `url(${eye_open ? EO : EC})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
  };
  const capitalize = (str) => {

    return str.split(' ').map(str => str.charAt(0).toUpperCase().concat(str.slice(1, str.length))).join(' ')
  }
  const handleRegister = async () => {
    if (formData.username.length > 0 && formData.email.length > 5) {
      if (formData.password.length > 8) {
        const hashed = bcrypt.hashSync(
          formData.password,
          "$2a$10$afDt.FnYK3LRoEWWrlWZQO"
        );

        let user_name = capitalize(formData.username);
        let nameArr = user_name.split(' ')
        let firstname = ""
        let secondname = "";
        if (nameArr.length > 1) { firstname = nameArr[0]; secondname = nameArr[1] }
        const sendFD = {
          username: user_name,
          first_name: firstname,
          last_name: secondname,
          password: hashed,
          email: formData.email.toLowerCase(),
          profile_pic: userProfileImagesArray[Math.floor(Math.random() * userProfileImagesArray.length)],
        };
        setIsRegistering(true)
        let res = await register(sendFD);
        if (res.response === "success") {
          setIsRegistering(false)
          dispatch(loginUser(sendFD));
          setIsSuccess(true)
          //alert("Your registration was a success!");
          //navigateTo("/");
        }
      } else {
        setIsShortPass(true)
      }
    } else {
      setIsFailIncomplete(true)
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLoaded(false);
    }, 100);

  }, []);
  return (
    <div className={loading ? "page" : "page loaded"}>
      <div style={{ minHeight: '8rem', minWidth: '100%', backgroundColor: '#222', marginTop: '-8rem', marginBottom: '4rem' }}></div>
      <div className="section_b_container register">
        <div className="section_content">
          <form className="register_form">
            <div className="registerImg"></div>
            <div className="register_input_grp">
              <div className="register_input_item">
                <label
                  className={
                    formData.unameF || formData.username.length > 0
                      ? "register_label focussed"
                      : "register_label"
                  }
                >
                  Full Name
                </label>
                <input
                  // placeholder="e.g Jane Doe"
                  className="register_input"
                  value={formData.username}
                  type="text"
                  onFocus={() => setFormData({ ...formData, unameF: true })}
                  onBlur={() => {
                    if (formData.username.length === 0)
                      setFormData({ ...formData, unameF: false });
                  }}
                  onInput={(e) =>
                    setFormData({
                      ...formData,
                      username: e.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="register_input_item">
                <label
                  className={
                    formData.emailF || formData.email.length > 0
                      ? "register_label focussed"
                      : "register_label"
                  }
                >
                  Email Address
                </label>
                <input
                  // placeholder="e.g johndoe@gmail.com"
                  className="register_input"
                  value={formData.email}
                  type="text"
                  onFocus={() => setFormData({ ...formData, emailF: true })}
                  onBlur={() => {
                    if (formData.email.length === 0)
                      setFormData({ ...formData, emailF: false });
                  }}
                  onInput={(e) =>
                    setFormData({ ...formData, email: e.currentTarget.value })
                  }
                />
              </div>
              <div className="register_input_item">
                <label
                  className={
                    formData.passF || formData.password.length > 0
                      ? "register_label focussed"
                      : "register_label"
                  }
                >
                  Password
                </label>
                <div
                  className="register_showPasswordBtn"
                  style={showPasswordBtn}
                  onClick={handleEyeClick}
                ></div>
                <input
                  className="register_input"
                  type={formData.passType}
                  value={formData.password}
                  onFocus={() => setFormData({ ...formData, passF: true })}
                  onBlur={() => {
                    if (formData.password.length === 0)
                      setFormData({ ...formData, passF: false });
                  }}
                  onInput={(e) =>
                    setFormData({
                      ...formData,
                      password: e.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="registerBtn" onClick={handleRegister}>
                REGISTER!
              </div>
            </div>
          </form>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: 100 }}
        open={isRegistering}

      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={isSuccess}
        autoHideDuration={5000}
        onClose={() => { setIsSuccess(false); navigateTo('/') }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => { setIsSuccess(false); navigateTo('/') }} severity="success" sx={{ width: '100%' }}>
          Registration Successful!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isFailIncomplete}
        autoHideDuration={5000}
        onClose={() => setIsFailIncomplete(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => { setIsFailIncomplete(false) }} severity="error" sx={{ width: '100%' }}>
          Please fill out All fields.
        </Alert>
      </Snackbar>
      <Snackbar
        open={isShortPass}
        autoHideDuration={5000}
        onClose={() => setIsShortPass(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => { setIsShortPass(false) }} severity="error" sx={{ width: '100%' }}>
        Passwords must be at least 8 characters long.
        </Alert>
      </Snackbar>
    </div>
  );
}
