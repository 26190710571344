import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import './question.css'
import { postAnswerToQuestion } from '../../api'
import * as Scroll from 'react-scroll';


const postAnswerSchema = {
    question_id: '',
    body: "",
    author: {},
    creation_date: '',
    date:0
}
export const Question = (props) => {
    console.log("IS IT MOBILE FROM Q? "+props.isMobile)
    const scroll = Scroll.animateScroll;
    const [isMobile, setIsMobile] = useState(props.isMobile)
    const [isLoaded, setLoaded] = useState(true)
    const [answersOpen, setAnswersOpen] = useState(false)
    const [postAnswer, setPostAnswer] = useState(postAnswerSchema)
    const [postAnswerOpen, setPostAnswerOpen] = useState(false)
    const currentUser = useSelector((state) => state.loginState.loggedInUser);
    let imgURL = props.question.author.profile_pic ? props.question.author.profile_pic.toString() : ''
    /*const AnswerModel = {
        body: 'This is a sample answer',
        author: {
            username: 'Michael Edwards',
            profile_pic: 'https://iqserve.org/img/userImg1.png',
            accesslevel: "Expert",
            is_mod: true,
        }
    }*/
    const imageBox = {
        backgroundImage: `url('${imgURL}')`,
        height: isMobile?'1rem':'3rem',
        minWidth: isMobile?'1rem':'3rem',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    }
    useEffect(() => {
        setTimeout(() => {
            //console.log("THE PROFILE PIC IS :"+props.question.profile_pic)
            setLoaded(true)

        }, 200)
       
    }, []);
    const openAnswers = () => {
        if (props.question.answers.length > 0) {
            setAnswersOpen(!answersOpen)
        }

    }
    const AnswerPost = () => {
        setAnswersOpen(false)
        setPostAnswerOpen(!postAnswerOpen)
    }
    const genAnsBoxImg = (img) => {
        return {
            backgroundImage: `url('${img}')`,
            height: isMobile?'1rem':'3rem',
            minWidth: isMobile?'1rem':'3rem',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        }
    }
    const handleASubmit = async () => {
        let sendAnswerData = { ...postAnswer, question_id: props.question._id, author: currentUser, creation_date: moment().format('yyyy-M-D h:mma'), date:new Date().getTime() }
        console.log("SENDING....")
        console.log(sendAnswerData)
        let res = await postAnswerToQuestion(sendAnswerData)
        if (res.response === "success") {
            setPostAnswer(postAnswerSchema)
            setPostAnswerOpen(false)            
            props.closeQ();
            props.refresh();
            setTimeout(()=>{
                scroll.scrollToTop();
                openAnswers();
            },500)
            
        } else {
            console.log(res.message)
        }
    }
    const Answer = (props) => {
        return (
            isMobile?
            <div className='answers_container'>
                <div>{props.children.body}</div>
                <div className='answer'>
                    <div className="userbox">
                        <div className='infobox_img' style={genAnsBoxImg(props.children.author.profile_pic)}></div>
                        <div className='userCol'>
                            <div className='user_name'>{props.children.author.username}</div>
                            <div className='user_dt'>{props.children.author.accesslevel}</div>
                            {props.children.author.is_mod ? <div className='user_dt'>Moderator</div> : <></>}
                        </div>
                    </div>
                    
                </div>
            </div>:
            <div className='answers_container'>
            <div className='answer row'>
                <div className="userbox">
                    <div className='infobox_img' style={genAnsBoxImg(props.children.author.profile_pic)}></div>
                    <div className='userCol'>
                        <div className='user_name'>{props.children.author.username}</div>
                        <div className='user_dt'>{props.children.author.accesslevel}</div>
                        {props.children.author.is_mod ? <div className='user_dt'>Moderator</div> : <></>}
                    </div>
                </div>
                <div>{props.children.body}</div>
            </div>
        </div>
        )
    }
    const isDesktop=()=>{
        return (
        
            <div className="question_container">
                <div className={isLoaded ? 'question loaded' : 'question'}  id={props.question._id}>
                    <div className='answerbox' onClick={openAnswers}>
                        <div className='answer-number'>{props.question.answers.length}</div>
                        <div className='answer'>answers</div>
                    </div>
                    <div className='question-content'>
                        <div className='question_title'>{props.question.title}</div>
                        <div className='question_body'>{props.question.body}</div>
                        <div className='question_buttons_row'>
                            <div className='tags_row'>
                                {props.question.tags.map(m =>
                                    <div className='tag' key={Math.random()}>{m}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='infobox'>
                        <div className='date_time'>{props.question.creation_date}</div>
                        <div className="userbox">
                            <div className='infobox_img' style={imageBox}></div>
                            <div className='userCol'>
                                <div className='user_name'>{props.question.author.username}</div>
                                <div className='user_dt'>{props.question.author.accesslevel}</div>
                                {props.question.author.is_mod ? <div className='user_dt'>Moderator</div> : <></>}
                            </div>
                        </div>
                    </div>
                    <div className='answerBtn' style={{ cursor: 'pointer', padding: '1rem', backgroundColor: '#79d411', color: 'white', fontSize: "14pt", fontWeight: "500" }} onClick={() => AnswerPost()}>Answer</div>
    
                </div>
                <div className={answersOpen ? 'answerSection' : 'answerSection hidden'}>
                    {props.question.answers.map(ans =>
                        <Answer key={ans._id}>{ans}</Answer>
                    )}
                </div>
                <div className={postAnswerOpen ? 'answerPostSection' : 'answerPostSection hidden'}>
                    <div className="qInputGrp">
                        <label className="qLabel"><span style={postAnswer.body.length > 175 ? { textSize: 'small', color: "red" } : { textSize: 'small', color: "teal" }}> - {200 - postAnswer.body.length}characters left</span></label>
                        <textarea
                            maxLength={200}
                            className="qBody"
                            value={postAnswer.body}
                            onInput={(e) => {
                                setPostAnswer({
                                    ...postAnswer,
                                    body: e.currentTarget.value,
                                });
                            }}
                        />
                    </div>
                    <div className="postAnswerBtn" style={currentUser.email.length > 0 ? { cursor: 'pointer' } : { cursor: 'not-allowed' }} onClick={() => currentUser.email.length > 0 ? handleASubmit() : null}>
                        Post Answer <span style={{ fontSize: 'small', color: 'white', fontVariant: 'small-caps' }}>{currentUser.email.length > 0 ? '' : '(login to post)'}</span>
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        isMobile?
        <div className="question_container">
            <div className={isLoaded ? 'question loaded' : 'question'}  id={props.question._id}>               
                <div className='question-content'>
                    <div className='question_title'>{props.question.title}</div>
                    <div className='question_body'>{props.question.body}</div>
                    
                        <div className='tags_row'>
                            {props.question.tags.map(m =>
                                <div className='tag' key={Math.random()}>{m}</div>
                            )}
                        </div>
                   
                     <div className='infobox'>
                    <div className='date_time'>{props.question.creation_date}</div>
                    <div className="userbox">
                        <div className='infobox_img' style={imageBox}></div>
                        <div className='userCol'>
                            <div className='user_name'>{props.question.author.username}</div>
                            <div className='user_dt'>{props.question.author.accesslevel}</div>
                            {props.question.author.is_mod ? <div className='user_dt'>Moderator</div> : <></>}
                        </div>
                    </div>
                </div>
                </div>
                <div className='row'>
                    <div className='answerbox' onClick={openAnswers}>
                        <div className='answer-number'>{props.question.answers.length}</div>
                        <div className='answer'>answers</div>
                    </div>
                    <div className='answerBtn' style={{ cursor: 'pointer', padding: '1rem', backgroundColor: '#79d411', color: 'white', fontSize: "14pt", fontWeight: "500" }} onClick={() => AnswerPost()}>Answer</div>
                </div>

            </div>
            <div className={answersOpen ? 'answerSection' : 'answerSection hidden'}>
                {props.question.answers.map(ans =>
                    <Answer key={ans._id}>{ans}</Answer>
                )}
            </div>
            <div className={postAnswerOpen ? 'answerPostSection' : 'answerPostSection hidden'}>
                <div className="qInputGrp">
                    <label className="qLabel"><span style={postAnswer.body.length > 175 ? { textSize: 'small', color: "red" } : { textSize: 'small', color: "teal" }}>{200 - postAnswer.body.length}characters left</span></label>
                    <textarea
                        maxLength={200}
                        className="qBody"
                        value={postAnswer.body}
                        onInput={(e) => {
                            setPostAnswer({
                                ...postAnswer,
                                body: e.currentTarget.value,
                            });
                        }}
                    />
                </div>
                <div className="postAnswerBtn" style={currentUser.email.length > 0 ? { cursor: 'pointer' } : { cursor: 'not-allowed' }} onClick={() => currentUser.email.length > 0 ? handleASubmit() : null}>
                    Post Answer <span style={{ fontSize: 'small', color: 'white', fontVariant: 'small-caps' }}>{currentUser.email.length > 0 ? '' : '(login to post)'}</span>
                </div>
            </div>
        </div>:isDesktop()
    )
}
