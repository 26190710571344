import React,{useEffect, useState} from "react";
import Lottie from 'lottie-react'
import image1 from '../../lottie/business_advice.json'
import image2 from '../../lottie/accountant.json'
import image3 from '../../lottie/compliance.json'
import './Landing_section2.css'
import { borderBottom } from "@mui/system";



export default function Landing_section2() {
    const [screenW, setScreenW] = useState(window.innerWidth)
    const categoryImgDiv = {
        width:'50%',
        maxHeight:'25rem',   
        margin:'0 auto'
    }
    const categories = {
        display:'flex',
        flexDirection:'column',
        width:'80%',
        margin:'0 auto',
        marginTop: '2rem'
    }
    const category ={
        display:'flex',
        flexDirection:'row',
        margin:'2rem 1em',
        border:'1pt solid grey',
        background:'white'
    
    }
    const categoryImg1 = {
        display:'flex',
        width:screenW<900?'auto':'auto',
        height:'20rem',
        borderRight:screenW<900?'none':'1pt solid grey',
        borderBottom:screenW<900?'1pt solid grey':'none'
       
    }
    const categoryImg2 = {
        width:screenW<900?'35rem':'auto',
        height:'20rem',
        display:'flex',
        borderLeft:screenW<900?'none':'1pt solid grey',
        borderBottom:screenW<900?'1pt solid grey':'none'
    
    }
    const categoryImg3 = {
        width:screenW<900?'35rem':'auto',
        height:'20rem',
        display:'flex',
        borderRight:screenW<900?'none':'1pt solid grey',
        borderBottom:screenW<900?'1pt solid grey':'none'
    }
    const categoryText = {
        paddingRight:'1rem',
        paddingLeft:'2rem',
        paddingTop:'2rem',
        width:'40%',
        textAlign:screenW<900?'center':'left',
        margin:screenW<900?'0 auto':'auto',
        paddingBottom:screenW<900?'4rem':'0',
        // borderLeft:'3pt solid tomato',
        // backgroundColor: '#fdf0e6'
    }
    const categoryTextRight = {
        paddingTop:'2rem',
        paddingLeft:'1rem',
        paddingRight:'2rem',
        width:'40%',
        margin:screenW<900?'0 auto':'auto',
        paddingBottom:screenW<900?'4rem':'0',
        // borderRight:'3pt solid tomato',
        // backgroundColor: '#fdf0e6'
    }
    const categoryHeading = {
        color:'#555',
        fontSize:'20pt',
        fontWeight:'600',
        marginBottom:'1rem',
        textAlign:screenW<900?'center':'left',
    }
    const categoryHeadingRight = {
        color:'#555',
        fontSize:'20pt',
        fontWeight:'600',
        marginBottom:'1rem',
        textAlign:screenW<900?'center':'right',
        paddingRight:screenW<900?'0':'1rem',
    }
    const categoryContent = {
        color:'#444',
        fontSize:'14pt',
        fontWeight:'300',
        lineHeight:'1.5',
        textAlign:screenW<900?'center':'left',
        listStyle:screenW<900?'none':'circle inside',
    }
    const categoryContentRight = {
        color:'#444',
        fontSize:'14pt',
        fontWeight:'300',
        lineHeight:'1.5',
        textAlign:screenW<900?'center':'right',
        paddingRight:screenW<900?'0':'1rem',
        listStyle:screenW<900?'none':'circle inside',
        
    }
    useEffect(()=>{
        function handleResize(){
            setScreenW(window.innerWidth);
        }
        window.addEventListener("resize", handleResize)
    },[]);

    const categoryArr = [
        {
            id:1,
            imagesrc:image1,
            imgstyle:categoryImg1,
            title:'Business Advisory',
            content:[
                {
                    id:1.1,
                    content:'Planning'
                },
                {
                    id:1.2,
                    content:'Business Process Re-engineering'
                },
                {
                    id:1.3,
                    content:'Business Model Assessment'
                },
                {
                    id:1.4,
                    content:'Business Growth Strategies'
                },
                {
                    id:1.5,
                    content:'Business Management Tool Development & Implementation'
                },
               
            ]
        },
        {
            id:2,
            imagesrc:image2,
            imgstyle:categoryImg2,
            title:'Accounting',
            content:[
                {
                    id:2.1,
                    content:'Book-Keeping'
                },
                {
                    id:2.2,
                    content:'Financial Statements'
                },
                {
                    id:2.3,
                    content:'Cash-Flow Forecasts'
                },
                {
                    id:2.4,
                    content:'Payroll Services'
                },               
               
            ]
        },
        {
            id:3,
            imagesrc:image3,
            imgstyle:categoryImg3,
            title:'Compliance',
            content:[
                {
                    id:3.1,
                    content:'Quarterly Tax Calculation'
                },
                {
                    id:3.2,
                    content:'VAT Return'
                },
                {
                    id:3.3,
                    content:'Annual Tax Return – Inland Revenue'
                },
                {
                    id:3.4,
                    content:'Annual Return – Legal Affairs'
                },
               
               
            ]
        }
    ]
  



  return (
    <div className="fancybackground section2-container">
      <div className="section2-content">
      <div className='section_heading' style={{margin:'0 auto'}}>
                    <div className='section_heading_title'>What we do</div>
                    <div className='section_heading_body'>Our services go beyond the field of accounting. We help our clients grow their businesses by giving them the support and professional advice when needed.</div>
                </div>
      
        <div style={categories} className='categories'>
            {categoryArr.map(cat =>(
                <div style={category} className='category' key={cat.id}>
                {cat.id%2>0||screenW<900?(<div className="categoryImgDiv" ><Lottie className="categoryImg" style={cat.imgstyle} animationData={cat.imagesrc} alt={cat.title} /></div>):'' }
                <div style={cat.id%2>0?categoryText:categoryTextRight}>
                    <div style={cat.id%2>0?categoryHeading:categoryHeadingRight}>{cat.title}</div>
                    <ul>
                        {cat.content.map(li=>(
                            <li key={li.id} style={cat.id%2===1?categoryContent:categoryContentRight}>{li.content}</li>
                        ))}
                    </ul>
                </div>
                {cat.id%2===0&&screenW>900?(<div className="categoryImgDiv" style={categoryImgDiv}>
                    <Lottie className="categoryImg" style={cat.imgstyle} animationData={cat.imagesrc} alt={cat.title} />
                </div>):'' }
                </div>
            ))}
        </div>
      </div>
    </div>
  );
}
