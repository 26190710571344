import React from 'react'
import './landing_banner.css'
export default function landing_banner() {  
  return (
    <div className="container-fluid landing_banner">
      <div className='banner' data-type='landing_banner'>
        <div className='banner_text'>Accounting, Bookkeeping, Taxes and Payroll</div>
        <div className='banner_text_sub'>STREAMLINED FOR SMALL BUSINESS AND STARTUPS</div>
      </div>
    </div>
  )
}
