import React from 'react'
import Lottie from 'lottie-react'
import logoanimation  from '../img/iqserve_animation.gif'
import loadingAnimation from '../lottie/loading.json'
import './LoadingPage.css'
export default function LoadingPage() {
  const data={
    logoX:150,
    logoY:50
  }
  return (
    <div className="loadingPage">
        <img className='iqanim' src={logoanimation}width={data.logoX} height={data.logoY} alt="IQServe loading animation"/>
        <Lottie animationData={loadingAnimation} loop style={{marginTop:"-0.5rem", width:150}}/>
    </div>
  )
}
