import React from 'react'
import {Acca, Icatt, Mip} from './icons/'
import './footer.css'
export default function footer() {
   
  return (
    <div className='footer'>
        <div className="container-fluid footer-content">
            <div className='prefooter' >
                <Acca />
                <Icatt />
                <Mip />
            </div>
            <div className='footer-bottom'><div className="footer-text"><a href="https://teemaltech-solutions.firebaseapp.com">Copyright 2022 TeemalTech Solutions</a></div></div>
        </div>
    </div>
  )
}
