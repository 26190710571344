import React, { useState, useEffect, useRef } from "react";
import "./forum.css";
import { Question } from "./components";
import { getQuestions, postQuestion } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { loadQuestions } from "../features/forumSlice";
import { useNavigate } from "react-router-dom";
import * as Scroll from 'react-scroll';
import moment from 'moment';
import { Backdrop, CircularProgress, Snackbar, Alert } from "@mui/material";


export const Forum = (props) => {
  
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const scroller = Scroll.scroller;
  const scroll = Scroll.animateScroll;
  const Element = Scroll.Element;
 
  
  
  const sortFunc = useRef(sortNewest);
  const isNewest = useRef(true)
  const [isQuestionInputOpen, setQuestionInputOpen] = useState(false)
  const [loading, setLoading] = useState(true);

  //const [isLoadingData, setIsLoadingData] = useState(true)
  const [isSubmittingForm, setIsSubmittingForm]=useState(false)
  const currentUser = useSelector((state) => state.loginState.loggedInUser);
  const forumState = useSelector((state) => state.forumState.questions);
  const [questArray, setquestArry] = useState(forumState)
  const questArrayFull = useRef([])
  /**
    title: String,
    body: String,
    tags: Array,
    author: String,
    profile_pic:String,
    creation_date:{
    type:Date,
    default:new Date()
  },
  location: String,
  answers: Array,
   */
  const blankQForm = {
    title: "",
    body: "",
    tags: [],
    author: {},
    answers: [],
    creation_date: "",
    date: 0
  };
  const [qFormData, setQFormData] = useState(blankQForm);
  function sortNewest(a, b) {
    let d1 = (a.date)
    let d2 = (b.date)
    if (d1 < d2) {
      return 1;
    }
    if (d1 > d2) {
      return -1;
    }
    return 0;
  }
  function sortOldest(a, b) {
    let d1 = (a.date)
    let d2 = (b.date)
    if (d2 < d1) {
      return 1;
    }
    if (d2 > d1) {
      return -1;
    }
    return 0;
  }

  const sortQuestions = () => {
    isNewest.current = !isNewest.current;
    if (isNewest.current) {
      sortFunc.current = sortNewest;
    } else {
      sortFunc.current = sortOldest;
    }
    refreshQuestions();
  }

  const refreshQuestions = async () => {
    if(!isSubmittingForm)setIsSubmittingForm(true)
    let qArray = await getQuestions();
    qArray.data.forEach((q) => {
      q.answers.sort(sortNewest)
    })
    questArrayFull.current = qArray.data;
    let qArraySorted = qArray.data;
    qArraySorted.sort(sortFunc.current);
    dispatch(loadQuestions(qArraySorted));
    //console.log(qArraySorted)
    setquestArry(qArraySorted)
    setIsSubmittingForm(false)
    //console.log("REFRESHED")
  };
  const handleQSubmit = async () => {
    if (currentUser.email.length === 0) {
      navigateTo("/login");
    } else {
      setIsSubmittingForm(true)
      //setQFormData({ ...qFormData, author:currentUser, creation_date:new Date()})
      //DOING THIS BECAUSE SETTING STATE IS ASYNCHONOUS AND ONLY HAPPENS UPON RE_RENDER
      let sendFormData = { ...qFormData, author: currentUser, creation_date: moment().format('yyyy-M-D h:mma'), date: new Date().getTime() }
      //console.log(sendFormData)
      //console.log(qFormData)
      let response = await postQuestion(sendFormData);
      if (response.response === "success") {

        setQFormData(blankQForm);
        setQuestionInputOpen(false)
        scroll.scrollToTop();
        refreshQuestions();
        //console.log("RESPONSE FROM SERVER: ")
        console.log(response.data)
        // const q = document.getElementById(response._id).classList.remove("loaded")
        // setTimeout(()=>{
        //   q.classList.add("loaded")
        // },1000)
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
    if(forumState.length===0)refreshQuestions();

  }, []);

  const openQuestionInput = () => {
    scroller.scrollTo("postQuestionInput", {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page

    })
    setQuestionInputOpen(true)

  }
  const closeQuestionInput = () => {

    setQuestionInputOpen(false)
  }
  return (
    <div className={loading ? 'page' : 'page loaded'}>
      <div style={{minHeight:'12rem',minWidth:'100%', backgroundColor:'#222',marginTop:'-8rem', marginBottom:'4rem'}}></div>
      <div className="fcontainer">
        <div className="fcontent">
          <div className="fheading">
            <div className="fheadingRow">
              <div className="ftitle" style={{ fontVariant: 'small-caps' }}>Forum</div>
              <div
                className="addQuestionBtn"
                onClick={openQuestionInput}
              >
                Ask a Question
              </div>
            </div>
            <div className="controlsHeadingRow">
              <div className="questCount">({questArrayFull.current.length}questions)</div>
              <div className="buttonsRow">
                <div className="sortBtn" style={{ cursor: 'pointer' }} onClick={sortQuestions}>{isNewest.current ? 'Oldest' : 'Newest'}</div>
                <input className="searchBar" placeholder="Search..." type="text" onInput={(e) => { setquestArry([...questArrayFull.current.filter(quest => quest.title.search(e.currentTarget.value) > -1)]) }} />
              </div>
            </div>
          </div>
          <div className="qArraySection">
            
            {questArray.map(q =>
              <Question key={Math.random()} question={q} closeQ={closeQuestionInput} refresh={refreshQuestions} isMobile={props.isMobile} />
            )}
          </div>
          <Element name="postQuestionInput" >
            <div className={isQuestionInputOpen ? "question_input_section" : "question_input_section hidden"} id="postq">

              <div className="qInputGrp">
                <label className="qLabel">Question Title <span style={qFormData.title.length > 60 ? { textSize: 'small', color: "red" } : { textSize: 'small', color: "teal" }}> - {75 - qFormData.title.length}characters left</span></label>
                <input
                  maxLength={75}
                  type="text"
                  className="qTitle"
                  value={qFormData.title}
                  onInput={(e) => {
                    //qTitle.current = e.currentTarget.value;
                    setQFormData({ ...qFormData, title: e.currentTarget.value })
                  }}
                />

              </div>
              <div className="qInputGrp">
                <label className="qLabel">Question Body <span style={qFormData.body.length > 175 ? { textSize: 'small', color: "red" } : { textSize: 'small', color: "teal" }}> - {200 - qFormData.body.length}characters left</span></label>
                <textarea
                  maxLength={200}
                  value={qFormData.body}
                  className="qBody"
                  onInput={(e) => {
                    setQFormData({ ...qFormData, body: e.currentTarget.value })
                  }}
                />
              </div>
              <div className="qInputGrp">
                <label className="qLabel">Tags</label>
                <input
                  maxLength={150}
                  type="text"
                  className="qTags"
                  placeholder="e.g. #accounting, #businessregistration, #taxadvice"
                  value={qFormData.tags.join(',')}
                  onInput={(e) => {
                    setQFormData({ ...qFormData, tags: e.currentTarget.value.split(' ') })
                  }}
                />

              </div>
              <div className="postQuestionBtn" style={currentUser.email.length > 0 ? { cursor: 'pointer' } : { cursor: 'not-allowed' }} onClick={() => currentUser.email.length > 0 ? handleQSubmit() : null}>
                Post Question <span style={{ fontSize: 'small', color: 'white', fontVariant: 'small-caps' }}>{currentUser.email.length > 0 ? '' : '(login to post)'}</span>
              </div>
            </div>
          </Element>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: 100 }}
        open={isSubmittingForm}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
