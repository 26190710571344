import React from 'react'

export default function piechart3() {
  return (
    <svg className='piechart' version="1.0" id="Layer_2" x="0px" y="0px"
	 viewBox="0 0 100 77.5" enableBackground="new 0 0 100 77.5">
<g>
	<g>
		<g>
			<g>
				<path fill="#FFFFFF" stroke="#000000" strokeWidth="0.25" strokeMiterlimit="10" d="M50.3,38.35L20.91,55.32
					c9.37,16.23,30.13,21.8,46.36,12.42s21.8-30.13,12.42-46.36C73.45,10.57,62.78,4.41,50.3,4.41V38.35z"/>
			</g>
		</g>
		<g>
			<g>
				<path fill="#FF6347" stroke="#000000" strokeWidth="0.25" strokeMiterlimit="10" d="M50.3,38.35V4.41
					c-18.74,0-33.94,15.2-33.94,33.94c0,6.24,1.43,11.56,4.55,16.97L50.3,38.35z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
		</g>
		<g>
		</g>
	</g>
</g>
</svg>
  )
}
