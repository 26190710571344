import React from 'react'

export default function piechart1() {
  return (
    <svg className='piechart' version="1.0" id="Layer_2" x="0px" y="0px"
	 viewBox="0 0 100 77.5" enableBackground="new 0 0 100 77.5">
<g>
	<g>
		<g>
			<g>
				<path fill="#FFFFFF" stroke="#000000" strokeWidth="0.25" strokeMiterlimit="10" d="M48.31,39.35L77.7,56.32
					c-9.37,16.23-30.13,21.8-46.36,12.42S9.54,38.62,18.91,22.38C25.15,11.57,35.82,5.41,48.31,5.41V39.35z"/>
			</g>
		</g>
		<g>
			<g>
				<path fill="#FF6347" stroke="#000000" strokeWidth="0.25" strokeMiterlimit="10" d="M48.31,39.35V5.41
					c18.74,0,33.94,15.2,33.94,33.94c0,6.24-1.43,11.56-4.55,16.97L48.31,39.35z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
		</g>
		<g>
		</g>
	</g>
</g>
</svg>

  )
}
