import React, { useEffect, useState } from "react";
import "./login.css";
import { Backdrop, CircularProgress, Snackbar,Alert } from "@mui/material";
import { useDispatch } from 'react-redux'
import { loginUser } from "../features/loginSlice";
import { login } from "../api";
import { useNavigate } from "react-router-dom";
import bcrypt from 'bcryptjs'

export default function Login() {
  const [loading, setLoaded] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false)
  const [emailFocussed, setEmailFocussed] = useState(false);
  const [passFocussed, setPassFocussed] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch()
  const navigateTo = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setLoaded(false);
    }, 100);
  }, []);
  const handleLogin = async() => {
    setIsLoggingIn(true)
    let fd = {
      email,
      password:bcrypt.hashSync(password,'$2a$10$afDt.FnYK3LRoEWWrlWZQO')
    }
    let res = await login(fd);
    setIsLoggingIn(false) 
    if(res.response==="success"){
      setIsSuccess(true)
      let user = res.data;      
      dispatch(loginUser(user))
      //navigateTo('/')
    }else{
      if(res.response==="fail"){
        if(res.message==="invalid creds"){
          setIsFail(true)
        }else{
          alert(res.message)
        }
      }
    }
    
  };
  const resetForm=()=>{
    setEmail('');
    setPassword('')
  }
  return (
    <div className={loading ? "page" : "page loaded"}>
      <div className="spacer" style={{minHeight:'8rem',minWidth:'100%', backgroundColor:'#222',marginTop:'-8rem', marginBottom:'4rem'}}></div>
      <div className="section_b_container login">
        <div className="section_content">
          <form className="login_form">
            <div className="loginImg"></div>
            <div className="login_input_grp">
              <div className="login_input_item">
                <label className={emailFocussed || email.length>0? "login_label focussed" : "login_label"}>
                  Email Address
                </label>
                <input
                  className="login_input"
                  value={email}
                  type="text"
                  onFocus={() => setEmailFocussed(true)}
                  onBlur={() => {
                    if (email.length === 0) setEmailFocussed(false);
                  }}
                  onInput={(e) => setEmail(e.currentTarget.value)}
                />
              </div>
              <div className="login_input_item">
                <label className={passFocussed || password.length>0? "login_label focussed" : "login_label"}>
                  Password
                </label>
                <input
                  className="login_input"
                  type="password"
                  value={password}
                  onFocus={() => setPassFocussed(true)}
                  onBlur={() => {
                    if (password.length === 0) setPassFocussed(false);
                  }}
                  onInput={(e) => setPassword(e.currentTarget.value)}
                />                
              </div>
              <div className="loginBtn" onClick={handleLogin}>
                  Login!
                </div>
                <div className="register_question">Don't have an account? Sign up <span id="registerbtn" onClick={()=>navigateTo('/register')}>here</span></div>
            </div>
          </form>
        </div>
      </div>
      <Backdrop
  sx={{ color: '#fff', zIndex: 100}}
  open={isLoggingIn}
  
>
  <CircularProgress color="inherit" />
</Backdrop>
<Snackbar
  open={isSuccess}
  autoHideDuration={2000}
  onClose={()=>{setIsSuccess(false);navigateTo('/')}}
  anchorOrigin={{vertical:'top',horizontal:'center'}}   
>
<Alert onClose={()=>{setIsSuccess(false);navigateTo('/')}} severity="success" sx={{ width: '100%' }}>
Login Successful!
  </Alert>
</Snackbar>
<Snackbar
  open={isFail}
  autoHideDuration={2000}
  onClose={()=>setIsFail(false)} 
  anchorOrigin={{vertical:'top',horizontal:'center'}} 
>
<Alert onClose={()=>{setIsFail(false);resetForm()}} severity="error" sx={{ width: '100%' }}>
Please check your email and password.
  </Alert>
</Snackbar>
    </div>
  );
}
