import { createSlice } from "@reduxjs/toolkit";

const initialState = {   
    landing_banner:"../../img/banner.jpg"  
}

export const imageSlice = createSlice({
    name:'images',
    initialState:initialState,   
});
export const {getImages} = imageSlice.actions;
export default imageSlice.reducer;