import React ,{useEffect, useState} from "react";
import { LBanner, LSect1, LSect2, LSect3, LSect4 } from "./components";
const Landing = () => {
  
  const [loading,setLoaded] = useState(true)
  useEffect(()=>{
    setTimeout(()=>{
      setLoaded(false)
    },100);
  })
  return (
    
    <div className={loading?'page':'page loaded'}>
      
      <LBanner />
      <LSect1 />
      <LSect2 />
      <LSect3 />
      <LSect4 />
    </div>
  );
};
export default Landing;
