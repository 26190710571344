import React, { useRef, useEffect, useState } from 'react'
import './news.css';
import txa from "../img/taxamnesty.png"
import pl from '../img/placeholder_express.jpg'
import { useSelector } from 'react-redux';



export const TaxNews = (props) => {
  const [isMobile, setIsMobile] = useState(props.isMobile)
  const newspapers = useRef(null)
  const expressArr = useSelector(state => state.news.express)
  const newsdayArr =  useSelector(state=>state.news.newsday)
  useEffect(() => {
    let articleLimit = 6;
    let expressroot = document.getElementById("express")
    let row;
    if(expressArr){
      for (let i = 0; i < articleLimit; i++) {

        let title_content = expressArr[i].title;
        let title_url = expressArr[i].url;
        let imgURL = expressArr[i].preview.url
          ? expressArr[i].preview.url
          : pl;
        // console.log(imgURL);
        if (i % 3 === 0 ||isMobile) {
          row = document.createElement('div');
          row.className = 'article_row';
          row.id = `express_article_${i}`;
          let article = document.createElement('article');
          article.className = 'article';
          const imgBlock = document.createElement('div');
          imgBlock.className = 'article-image-block';
          imgBlock.style.backgroundImage = 'url(' + imgURL + ')';
          imgBlock.style.backgroundRepeat = 'no-repeat';
          imgBlock.style.backgroundSize = 'cover';
          imgBlock.style.backgroundPosition = 'center';
  
          const title = document.createElement('a');
          title.setAttribute('href', title_url);
          title.innerHTML = title_content;
          article.appendChild(imgBlock);
          article.appendChild(title);
  
          article.classList.add('animate__animated', 'animate__fadeIn');
          row.appendChild(article);
        } else {
          let article = document.createElement('article');
          article.className = 'article';
          const imgBlock = document.createElement('div');
          imgBlock.className = 'article-image-block';
          imgBlock.style.backgroundImage = 'url(' + imgURL + ')';
          imgBlock.style.backgroundRepeat = 'no-repeat';
          imgBlock.style.backgroundSize = 'cover';
          imgBlock.style.backgroundPosition = 'center';
  
          const title = document.createElement('a');
          title.setAttribute('href', title_url);
          title.innerHTML = title_content;
          article.appendChild(imgBlock);
          article.appendChild(title);
  
          article.classList.add('animate__animated', 'animate__fadeIn');
          row.appendChild(article);
        }
        expressroot.append(row);
      }
    }
    if(newsdayArr){
      for (let i = 0; i < articleLimit; i++) {
        //console.log(newsdayArr[i]);
        if (i % 3 === 0||isMobile) {
          row = document.createElement('div');
          row.className = 'article_row';
          row.id = `newsday_article_${i}`;
          let article = document.createElement('article');
          article.className = 'article';
          article.innerHTML = newsdayArr[i];
          article.classList.add('animate__animated', 'animate__fadeIn');
          row.appendChild(article);
        } else {
          let article = document.createElement('article');
          article.className = 'article';
          article.innerHTML = newsdayArr[i];
          article.classList.add('animate__animated', 'animate__fadeIn');
          row.appendChild(article);
        }
        document.getElementById('newsday').append(row);
       
      }
    }




  }, [])

  return (
    <div className='page loaded'>
      <div style={{ minHeight: '8rem', minWidth: '100%', backgroundColor: '#222', marginTop: '-8rem', marginBottom: '4rem' }}></div>
      <div className="container-fluid news-container">
        <div className="container-fixed news-content">
          <div className='article_main'>
            <header className='news-header'>
              <h1>Tax Amnesty 2023</h1>
              <a className='sub_source' href="https://www.ird.gov.tt/taxamnesty/2023">Source:[Inland Revenue Division Website]</a>
            </header>
            <p className='taximg'><img alt='taxamnesty-2023' src={txa} /></p>
            <p>Act No. 21 of 2022, provides for a Tax Amnesty for the period 14<sup>th</sup>
              November 2022 to 17<sup>th</sup> February, 2023. This facility allows for the
              waiver of penalties and interest charges payable by taxpayers on outstanding tax
              liabilities in relation to taxes under the revenue laws.</p>
            <div className="item_grp">
              <h3>Waiver Of Interest</h3>
              <p>The Act provides for the waiver of:</p>
              <ul className='news_list1'>
                <li className='news_li'>Interest on any outstanding taxes, where they are due and payable for the
                  years up to and including the year ending 31<sup>st</sup> December, 2021 and
                  that the <strong><em>taxes are paid during</em></strong> the period of the
                  Tax Amnesty.</li>
                <li className='news_li'>Outstanding interest charged on any outstanding tax due and payable for the
                  years up to 31<sup>st</sup> December, 2021, where the outstanding
                  <strong><em>taxes are paid during</em></strong> the period of the Tax
                  Amnesty.</li>
                {/*li>Interest on any outstanding taxes, where they are due and payable for the period 1<sup>st</sup> January, 2021 to 31<sup>st</sup> May, 2021 and that the <strong><em>taxes are paid during</em></strong> the period of the Tax Amnesty.</li>
                <li>Outstanding interest charged on any outstanding tax due and payable for the period 1<sup>st</sup> January, 2021 to 31<sup>st</sup> May, 2021, where the outstanding <strong><em>taxes are paid during</em></strong> the period of the Tax Amnesty.</li*/}
              </ul>
            </div>
            <div className="item_grp">
              <h3>Waiver Of Penalties</h3>
              <p>Additionally, a waiver of penalties is applicable on or to:</p>
              <ul>
                <li className='news_li'>Any tax or outstanding tax or interest for the years up to and including the
                  year ending 31<sup>st</sup> December, 2021, where the outstanding
                  <strong><em>taxes are paid during</em></strong> the period of the Tax
                  Amnesty.</li>
                <li className='news_li'>Any outstanding return for the years up to and including the year ending
                  31<sup>st</sup> December, 2021, where the
                  <em><strong>return is filed during</strong></em> the period of the Tax
                  Amnesty.</li>
                {/*li>Any tax or outstanding tax or interest for the period 1<sup>st</sup> January, 2021 to 31<sup>st</sup> May, 2021, where the outstanding <strong><em>taxes are paid during</em></strong> the period of the Tax Amnesty.</li>
                <li>Any outstanding return for the period 1<sup>st</sup> January, 2021 to 31<sup>st</sup> May, 2021, where the <em><strong>return is filed during</strong></em> the period of the Tax Amnesty.</li*/}
              </ul>
            </div>
            <h3>Payment Centers</h3>
            <p>Payments can be made in cash, cheque, or Linx<sup>®</sup> at any of the
              following offices of the Inland Revenue Division:</p>
            <h4>Regional Offices</h4>
            <ul>
              <li className='news_li'><a title="Port of Spain - IRD Tower" href="/location/pos" target="_self">Port of Spain</a></li>
              <li className='news_li'><a title="San Fernando - Cipero Street" href="/location/san_fernando-cipero_street" target="_self">San Fernando
                - Cipero Street</a></li>
              <li className='news_li'><a title="Scarborough" href="/location/scarborough" target="_self">Scarborough</a></li>
            </ul>
            <h4>District Revenue Offices</h4>
            <ul>
              <li className='news_li'><a title="Chaguanas" href="/location/chaguanas" target="_self">Chaguanas</a>
              </li>
              <li className='news_li'><a title="Couva" href="/location/couva" target="_self">Couva</a></li>
              <li className='news_li'><a title="Point Fortin" href="/location/point_fortin" target="_self">Point
                Fortin</a></li>
              <li className='news_li'><a title="Port of Spain - St. George West" href="location/pos" target="_self">Port of Spain</a></li>
              <li className='news_li'><a title="Princes Town" href="/location/princes_town" target="_self">Princes
                Town</a></li>
              <li className='news_li'><a title="Rio Claro" href="/location/rio_claro" target="_self">Rio Claro</a>
              </li>
              <li className='news_li'><a title="San Fernando - Independence Avenue" href="/location/san_fernando-independence_avenue" target="_self">San
                Fernando - Independence Avenue</a></li>
              <li className='news_li'><a title="Sangre Grande" href="/location/sangre_grande" target="_self">Sangre Grande</a></li>
              <li className='news_li'><a title="Siparia" href="/location/siparia" target="_self">Siparia</a></li>
              <li className='news_li'><a title="Tunapuna" href="/location/tunapuna" target="_self">Tunapuna</a>
              </li>
            </ul>
            <p />
            <p><b>Please note the following:</b></p>
            <ul>
              <li className='news_li'>The waiver <strong>shall not</strong> apply to any interest and penalties
                paid <strong>prior to 14<sup>th</sup> November, 2022</strong>;</li>
              <li className='news_li'>Only Tax returns for the (6) year period (2016 – 2021) are valid for
                filing in 2022;</li>
              <li className='news_li'>Taxes and returns that remain outstanding after the amnesty ends,
                <strong>will be subject to the revived penalties and interest and become payable</strong>,
                as if the waiver was never effected.</li>
            </ul>
            <p>&nbsp;</p>
            <p><a className="readMore" href="https://www.ird.gov.tt/news" target="_blank" rel="noopener noreferrer">More Inland Revenue News</a></p>
          </div>
        
          <div className='newsSourceText'>More News... [Source: <a href='https://trinidadexpress.com'>https://trinidadexpress.com</a>]</div>
          <div className='newspapers' ref={newspapers} id="express"></div>
          
          <div className='newsSourceText'>[Source: <a href='https://newsday.co.tt'>https://newsday.co.tt</a>]</div>
          <div className='newspapers' ref={newspapers} id="newsday"></div>
        </div>
        
      </div>
    </div>
  )
}
