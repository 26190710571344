import { configureStore } from "@reduxjs/toolkit";
import loginReducer from '../features/loginSlice';
import forumReducer from '../features/forumSlice';
import newsReducer from '../features/newsSlice';
import imageReducer from '../features/imageSlice';

const store = configureStore({
    reducer:{
        loginState:loginReducer,
        forumState:forumReducer,
        news:newsReducer,
        images:imageReducer
    }
})
export default store;
