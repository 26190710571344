import React from "react";

export default function piechart2() {
  return (
    <svg
      className="piechart"
      version="1.0"
      id="Layer_2"      
      x="0px"
      y="0px"
      viewBox="0 0 100 77.5"
      enableBackground="new 0 0 100 77.5"      
    >
      <g>
        <g>
          <g>
            <g>
              <path
                fill="#FFFFFF"
                stroke="#000000"
                strokeWidth="0.25"
                strokeMiterlimit="10"
                d="M50.3,38.36l29.39,16.97
					c9.37-16.23,3.81-36.99-12.42-46.36S30.28,5.16,20.91,21.39c-6.24,10.81-6.24,23.13,0,33.94L50.3,38.36z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill="#FF6347"
                stroke="#000000"
                strokeWidth="0.25"
                strokeMiterlimit="10"
                d="M50.3,38.36L20.91,55.33
					c9.37,16.23,30.13,21.8,46.36,12.42c5.41-3.12,9.3-7.02,12.42-12.42L50.3,38.36z"
              />
            </g>
          </g>
        </g>
        <g>
          <g></g>
          <g></g>
        </g>
      </g>
    </svg>
  );
}
