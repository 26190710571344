import React from "react";
import './marquis.css';
import { PinIcon, PhoneIcon, FacebookIcon, TwitterIcon, MailIcon } from "./icons";
function Marquis() {
  return (
    <div className="container-fluid marquis-container">
      <div className="container-fixed marquis-content">
        <div className="marquis_location row">
          <a
            className="row"
            rel="nofollow noreferrer noopener"
            target="_blank"
            type="location"
            href="https://www.google.com/maps/place/Pine+Ridge+Heights,+Arouca/@10.639599,-61.3367612,16z/data=!3m1!4b1!4m5!3m4!1s0x8c36001b5f29ea3b:0xbe4b1180c511e08f!8m2!3d10.6388649!4d-61.3334153"
          >
            <PinIcon />
            <div className="marquis_location_text">Pineridge Heights, Arouca</div>
          </a>

        </div>
        <div className="marquis_phone">
          <a className='row' rel="nofollow noreferrer noopener"
            target="_blank"
            type="location"
            href="tel:+1 868-473-5629">
            <PhoneIcon />
            <div className="marquis_phone_text">(868) 473-5629</div>
          </a>
        </div>
        <div className="socials">
          <a style={{ color: 'white' }} href="https://www.facebook.com/iqserve"><FacebookIcon /></a>
          <a style={{ color: 'white' }} href="https://twitter.com/iqserve"><TwitterIcon /></a>
          <a style={{ color: 'white' }} href="mailto:michael.edwards@iqserve.org"><MailIcon /></a>
        </div>
      </div>
    </div>
  );
}

export default Marquis;
