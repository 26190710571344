import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    questions:[]
}

export const forumSlice = createSlice({
    name:'forumState',
    initialState,
    reducers:{
        loadQuestions: (state, formdata)=>{
            //console.log("SETTNG STATE FROM LOAD QUESTIONS")
            state.questions =  formdata.payload;
        }
    }
})

export const {loadQuestions} = forumSlice.actions;
export default forumSlice.reducer;