import React from 'react'
import { Route,Routes } from 'react-router-dom'
import * as Page from '../pages'
export default function AppRoutes(props) {
  return (
    <Routes>
      <Route path="/" element={<Page.Landing />} />
      <Route path="/login" element={<Page.Login />} />
      <Route path="/register" element={<Page.Register />} />
      <Route path="/forum" element={<Page.Forum isMobile = {props.isMobile}/>} />
      <Route path="/tax-news" element={<Page.TaxNews  isMobile = {props.isMobile} />} />
      <Route path="/contact-us" element={<Page.Contact  isMobile = {props.isMobile}/>} />
      
    </Routes>
  )
}
