import React, { useState, useEffect, useRef } from 'react';

import { Branding, Marquis } from '.';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../features/loginSlice';
import './navbar.css';
import { Avatar } from '@mui/material';
const Navbar = () => {
    const navLinks = [
        {
            id: 1,
            title: 'Home',
            path: '/',
            isActive: false
        },
        {
            id: 2,
            title: 'Forum',
            path: '/forum',
            isActive: false
        },
        {
            id: 3,
            title: 'News',
            path: '/tax-news',
            isActive: false
        },
        // {
        //     id:4,
        //     title:'About Us',
        //     path:'/about-us',
        //     isActive:false
        // },
        {
            id: 5,
            title: 'Contact Us',
            path: '/contact-us',
            isActive: false
        },

    ]
    const currentUser = useSelector((state) => state.loginState.loggedInUser);
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [mmState, setMMState] = useState(true);
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isMobile, setMobile] = useState(false)
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const [isScrolled, setScrolled] = useState(false);
    const navigation = useRef(null)
    const goLogout = () => {
        //navigateTo('/')
        dispatch(logoutUser());
        setIsLoggedIn(false)
    }

    useEffect(() => {
        function handleClickOutside(e) {
            if (navigation.current && !navigation.current.contains(e.target)) {
                setMMState(true)
                setPopoverOpen(false)
            }

        }
        function handleScoll(e) {
            if (e.currentTarget.scrollY >= 100) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }
        window.addEventListener("scroll", handleScoll)
        window.addEventListener("resize", handleResize)
        document.addEventListener("mousedown", handleClickOutside)
        
    }, []);
    useEffect(()=>{
       
        if(currentUser.email.length>0)setIsLoggedIn(true)
    },[currentUser]);
    const handleResize = () => {
        if (window.screen.availWidth < 768) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }
    const openMenu = () => {
        window.scrollTo(0, 0)
        setMMState(!mmState)
    }
    const goClientPortal = () => {

        if (!isLoggedIn) {
            navigateTo('/login')
        } else {
            navigateTo('/client-portal')
        }
    }

    const classGen = (p) => {
        var returnClass = 'link'
        if (currentPath === p) {
            returnClass += (' active')
        } else {
            returnClass.replace(' active', '')
        }
        if (isScrolled) {
            returnClass += (' scrolled')
        } else {
            returnClass.replace(' scrolled', '')
        }
        return returnClass;
    }
    return (
        <div className='navigation' ref={navigation}>
            <Marquis />
            <div>
                <nav className={!isScrolled ? 'container-fluid navbar row' : 'container-fluid navbar row scrolled'}>
                    <div className="container-fixed navbar_content">
                        <Branding isScrolled={isScrolled} />
                        <ul className='navlinks'>
                            {navLinks.map(item => (
                                <li className='navlink' key={item.id}>
                                    <Link className={classGen(item.path)} to={item.path} onClick={() => setCurrentPath(item.path)}>
                                        {item.id !== 2 ? item.title :
                                        <div style={{ position: "relative", display: "flex", flexDirection: "column", marginTop: "0rem" }}>
                                            <div style={{ position: 'absolute', top: '-0.75rem', right: '0px', color: "red", fontSize: "8pt", fontVariant: "all-small-caps" }}>NEW</div>
                                            <div>{item.title}</div>
                                        </div>}
                                    </Link>
                                </li>
                            ))}
                            {isLoggedIn ?
                                <>
                                    {/* <li className='navlink' key='7' ><button className={classGen('/client')} id='clientportal' onClick={goLogout}>Log out</button></li> */}
                                    {/* <li className='navlink' key='6' ><button className={classGen('/client')} id='clientportal' onClick={goClientPortal}>Hi, {currentUser.first_name}</button></li> */}
                                   
                                        <div  id='clientportalSelect' className='navlink'>
                                            <div className={classGen('/client')}  onClick={()=>setPopoverOpen(!popoverOpen)} style={{display:'flex', flexDirection:'row', overflowX:'clip', alignItems:'center', justifyContent:'space-around'}}><div style={{ marginRight:'0.5rem', width:'max-content', textAlign:'right'}}>{currentUser.first_name}</div><Avatar sx={{ bgcolor: 'tomato', height:24, width:24 }}/></div>
                                            <div className={popoverOpen?'popover popen':'popover'} onClick={goLogout}>Log out</div>
                                        </div>
                                 
                                </>
                                :
                                <li className='navlink' key='8' ><Link className={classGen('/client')} id="clientportal" to="/login">Log in</Link></li>
                            }
                           
                        </ul>
                        <button onClick={openMenu} className="hamburger_menu_btn"><div className={isScrolled ? 'hamburger_menu_bar scrolled' : 'hamburger_menu_bar'}></div><div className={isScrolled ? 'hamburger_menu_bar scrolled' : 'hamburger_menu_bar'}></div><div className={isScrolled ? 'hamburger_menu_bar scrolled' : 'hamburger_menu_bar'}></div></button>
                    </div>
                </nav>
            </div>
            <nav className={mmState ? 'mobile_nav' : 'mobile_nav open'}>
                <ul className='navlinks_mobile'>
                    {navLinks.map(item => (
                        <li className='navlink_mobile' key={item.id}><Link className={currentPath === item.path ? 'link active' : 'link'} to={item.path} onClick={() => { setCurrentPath(item.path); openMenu(); }}>{item.title}</Link></li>
                    ))}
                    {isLoggedIn?
                        <li className='navlink_mobile' key='7' ><button className={classGen('/client')} id='logoutBtn_mobile' onClick={() => { goLogout();; openMenu(); }}>Log out</button></li> :
                        <li className='navlink_mobile' key='8' ><Link to="/login" className={classGen('/client')} id='loginBtn_mobile' onClick={() => { openMenu(); }}>Log in</Link></li>}

                </ul>
            </nav>
        </div>
    )
}

export default Navbar;