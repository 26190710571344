import React from "react";
import logo from "../../img/Logo_new_white_wide.svg";
import logo_scrolled from "../../img/Logo_new_grey_wide.svg";
import { Link } from "react-router-dom";
import './branding.css'
export default function Branding(props) {
  //const [isScrolled,setScrolled] = useState(props.isScrolled)
  const data = {
    logoDimX: 150,
    logoDimY:50,
    brandingText: "IQSERVE",
    brandingSub: "Serving you Intelligently",
  };

  return (
    <div className="branding">
      <Link to="/">
        <img
          className="branding_logo"
          src={props.isScrolled ? logo_scrolled : logo}
          width={data.logoDimX}
          height={data.logoDimY}
          alt="iqserve logo"
        />
        <div className="branding_text_grp">
          {/* <div
            className={
              props.isScrolled ? "branding_text scrolled" : "branding_text"
            }
          >
            {data.brandingText}
          </div> */}
          <div
            className={
              props.isScrolled ? "branding_sub scrolled" : "branding_sub"
            }
          >
            {data.brandingSub}
          </div>
        </div>
      </Link>
    </div>
  );
}
