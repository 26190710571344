import React, { useEffect, useState } from "react";
import AppRoutes from "../routes";
import { LoadingPage } from '../pages';
import { Navbar, Footer } from "../pages/components";
import { getQuestions, loadLoopTT, loadNewsDay, loadExpress } from '../api/';
import { loadQuestions } from "../features/forumSlice";
import { useDispatch } from "react-redux";

import './App.css';
import './mqueries.css'
import { saveExpress, saveNewsday } from "../features/newsSlice";

function App() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const dispatch = useDispatch();
  function sortNewest(a, b) {
    let d1 = (a.date)
    let d2 = (b.date)
    if (d1 < d2) {
      return 1;
    }
    if (d1 > d2) {
      return -1;
    }
    return 0;
  }
  const fetchForumData = async () => {
    try {
      let qArray = await getQuestions()
      qArray.data.forEach((q) => {
        q.answers.sort(sortNewest)
      })
      let qArraySorted = qArray.data;
      qArraySorted.sort(sortNewest);
      dispatch(loadQuestions(qArraySorted));
    } catch (error) {

    }
  }
  fetchForumData()
  // const fetchLooptTT = async () => {
  //   let loopTTArr = await loadLoopTT();
  //   //dispatch(saveNews({loopTT:loopTTArr}))
  // }
  const fetchNewsDay = async ()=>{
   let newsdayArr =  await loadNewsDay();
   dispatch(saveNewsday(newsdayArr))
  }
  fetchNewsDay()
  const fetchExpress = async ()=>{
    let expressArr =  await loadExpress();
    dispatch(saveExpress(expressArr))
   }
   fetchExpress()
  const handleResize = () => {
    
    if (window.innerWidth < 480) {
      setIsMobile(true)  
    } else {
      setIsMobile(false)      
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 5000)
    if (window.innerWidth< 768) {
      setIsMobile(true)  
       
    } else {
      setIsMobile(false)      
    }
    window.addEventListener("resize", handleResize)
  }, [])
  return (
    <div className={isMobile ? "container-fluid-mobile" : "container-fluid"}>
      {
        isLoaded ? <><Navbar isMobile={isMobile} /><AppRoutes isMobile={isMobile} /><Footer isMobile={isMobile} /></> : <LoadingPage isMobile={isMobile} />
      }
    </div>
  );
}
export default App;
