import { createSlice } from "@reduxjs/toolkit";

const initialState = {    
    loopTT:[],
    newsday:[],
    express:[],   
}

export const newsSlice = createSlice({
    name:'news',
    initialState:initialState,
    reducers:{
        saveNewsday:(state, newsData)=>{
            state.newsday = newsData.payload;
        },
        saveExpress:(state, newsData)=>{
            state.express = newsData.payload;
        }
    }
})

export const {saveNewsday,saveExpress} = newsSlice.actions;
export default newsSlice.reducer;