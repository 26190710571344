import React from 'react'
import { PieChart1,PieChart2,PieChart3 } from './icons'
import './landing_section1.css'
export default function landing_section1() {
    const pillars = [
        {
            id:1,
            title:'Ingenuity',
            icon:PieChart1,
            content:'We take the time to learn about your practice and customize a solution for your professional corporation.'
        },
        {
            id:2,
            title:'Professionalism',
            icon:PieChart2,
            content:'Our clients are guaranteed the highest standards of ethics, service and reliability.'
        },
        {
            id:3,
            title:'Experience',
            icon:PieChart3,
            content:'We have a wide portfolio of satisfied clients, spanning several disciplines and industries.'
        }
    ]
  return (
    <div className="section1_container" id='services'>
        <div className="container-fixed section1_content">
                <div id="section1_heading">
                  Accountancy Services you can rely on.
                </div>
                <p className="section1_sub">
                  At iQserve, we believe in bringing exceptional value to our
                  clients through a combination of our experience, professionalism,
                  commitment and ingenuity.
                </p>
                <div id="contact_button">Secure a Professional Consultation</div>
                <div className='pillars'>
                    {pillars.map(pillar =>(
                        <div className='pillar' key={pillar.id}>
                            {<pillar.icon />}
                            <div className="pillar_text">
                                <div className='pillar_title'>{pillar.title}</div>
                                <div className='pillar_content'>{pillar.content}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='pillars_mobile'>
                    {pillars.map(pillar =>(
                        <div className='pillar_mobile' key={pillar.id}>
                            {<pillar.icon />}
                            <div className="pillar_text_mobile">
                                <div className='pillar_title_mobile'>{pillar.title}</div>
                                <div className='pillar_content_mobile'>{pillar.content}</div>
                            </div>
                        </div>
                    ))}
                </div>
        
        </div>
    </div>
  )
}
