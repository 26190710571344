import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loggedInUser: {
    name: "",
    first_name:"",
    last_name:"",
    email: "",
    phone:"",
    address:"",
    profile_pic:"",
    accesslevel:"User",
    is_mod:false,

  },
};
export const loginSlice = createSlice({
  name: "loginState",
  initialState,
  reducers: {
    loginUser: (state, serverRes) => {
      state.loggedInUser = serverRes.payload;
    },
    logoutUser: (state) => {
      state.loggedInUser = initialState.loggedInUser;
    },
  },
});

export const { loginUser, logoutUser } = loginSlice.actions;
export default loginSlice.reducer;
